let stepsBlock = document.querySelector('.steps');
let prevSlide = 0;
let activeSlide = 1;
let duration = 7000;
let imageSteps = null;
let contentSteps = null;

if (stepsBlock) {
    imageSteps = stepsBlock.querySelectorAll('.step-image');
    contentSteps = stepsBlock.querySelectorAll('.step-content');
    
    imageSteps[0].classList.add("active");
    contentSteps[0].classList.add("active");
    
    var animation = setInterval(loopAnimation, duration);


    contentSteps.forEach((step, i) => {
        step.addEventListener('mouseover', function(event) {     
            clearInterval(animation);
            clearAll();
            contentSteps[i].classList.add("active");
            imageSteps[i].classList.add("active");
        });

        step.addEventListener('mouseout', function(event) {    
            contentSteps[i].classList.remove("active");
            imageSteps[i].classList.remove("active");
            contentSteps[prevSlide].classList.add("active");
            imageSteps[prevSlide].classList.add("active");
            animation = setInterval(loopAnimation, duration);
        });
    });
}

function stopAnimation(animation) {
    clearInterval(animation);
}

function loopAnimation() {
    imageSteps[activeSlide].classList.add("active");
    contentSteps[activeSlide].classList.add("active");
    imageSteps[prevSlide].classList.remove("active");
    contentSteps[prevSlide].classList.remove("active");

    if(activeSlide >= imageSteps.length-1) {
        activeSlide = 0;
        prevSlide = imageSteps.length-1;
    } else {
        prevSlide = activeSlide;
        activeSlide++;
    }
}

function clearAll() {
    contentSteps.forEach((step, i) => {
        imageSteps[i].classList.remove("active");
        step.classList.remove("active");
    });
}