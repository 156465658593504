let mainHero = document.querySelector(".main-hero-top");

if(mainHero) {
    /*let title = document.querySelector(".main-hero-top h1");
    let titleTargetPosition = document.querySelector(".main-hero-bottom h1");
    
    let scrollTop = document.documentElement.scrollTop;
    let titleFromTop = title.getBoundingClientRect().top;
    let targetFromTop = titleTargetPosition.getBoundingClientRect().top;

    let initialDistanse = (targetFromTop) - (titleFromTop);
    let distanseToTarget = initialDistanse;
    
    window.onscroll = function() {
        distanseToTarget =  (targetFromTop) - (titleFromTop);
        scrollTop = document.documentElement.scrollTop;

        console.log('Distanse to target', distanseToTarget);
        console.log('Scroll top', scrollTop);
        console.log('initialDistanse', initialDistanse);
        
        if(scrollTop/2 < initialDistanse) 
            title.style.transform = `translateY(${scrollTop / 2}px)`;
    };

    window.onresize = function() {
        console.log('Title from top', titleFromTop);
        console.log('target from top', targetFromTop);
        
    };*/
}