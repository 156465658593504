import { moveGlide } from './_skew-slider-configuration.js';

let skewSlider = document.querySelector('.skew-slider');
let clientX = 0;
let clientY = 0;

if(skewSlider) {
    let arrowPrev = skewSlider.querySelector('.glide__arrows button.prev');
    let arrowNext = skewSlider.querySelector('.glide__arrows button.next');
    let cursorElements = skewSlider.querySelectorAll('.move-with-cursor');
    let nextField = skewSlider.querySelectorAll('.cursor-next-field');
    let prevField = skewSlider.querySelectorAll('.cursor-prev-field');
    let slides = skewSlider.querySelectorAll('.glide__slide');
    let slideVideo = skewSlider.querySelectorAll('.glide__slide video');
    let slideVideoControl = skewSlider.querySelector('.video-control');
    let slideVideoControlMobile = skewSlider.querySelectorAll('.video-control-mobile');


    window.addEventListener("mousemove", e => {
        clientX = e.clientX;
        clientY = e.clientY;

        cursorElements.forEach(element => {
            element.style.left = clientX+'px';
            element.style.top = clientY+'px';
        });
    });

    slideVideo.forEach((element, index) => { 
        element.addEventListener('mouseover', e => {
            slideVideoControl.classList.add('active');
        });

        element.addEventListener('mouseleave', e => {
            slideVideoControl.classList.remove('active');
        });

        element.addEventListener('click', e => {
            if(slideVideoControl.classList.contains('playing')) {
                element.parentElement.classList.remove('active');
                slideVideoControl.classList.remove('playing');
                slideVideoControlMobile[index].classList.remove('playing');
            } else {
                element.parentElement.classList.add('active');
                slideVideoControl.classList.add('playing');
                slideVideoControlMobile[index].classList.add('playing');
            }
        });
    });

    nextField.forEach(element => {
        element.addEventListener("mouseover", e => {
            arrowNext.classList.add('active');
        });

        element.addEventListener("mouseleave", e => {
            arrowNext.classList.remove('active');
        });

        element.addEventListener("click", e => {
            moveGlide('>');
        });
    });

    prevField.forEach(element => {
        element.addEventListener("mouseover", e => {
            arrowPrev.classList.add('active');
        });

        element.addEventListener("mouseleave", e => {
            arrowPrev.classList.remove('active');
        });

        element.addEventListener("click", e => {
            moveGlide('<');
        });
    });

    slideVideoControlMobile.forEach((videoControl, index) => {
        videoControl.addEventListener('click', e => {
            if(slideVideo[index].paused) {
                slideVideo[index].play();
                slideVideo[index].parentElement.classList.add('active');
                videoControl.classList.add('playing');
            } else {
                slideVideo[index].pause();
                slideVideo[index].parentElement.classList.remove('active');
                videoControl.classList.remove('playing');
            }
        });
    });

}