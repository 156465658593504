import Glide from "@glidejs/glide";
import { intersectionObserve } from './_intersection-observer.js';

let glide = null;

function initImageSlider(id) {
    glide = new Glide('#' + id, {
        type: 'carousel',
        rewind: false, 
        bound: true,
        startAt: 0,
        gap: 24,
        perView: 1,
        breakpoints: {
            900: {
                perView: 1,
                gap: 12,
            }
        }
    });

    if(window.innerWidth < 960) {
        glide.on('mount.after', function () {
            const elements = document.querySelectorAll('.slideshow-for-graphics video');
    
            intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
                element.play();
            });
        });
    } else {
        glide.on('mount.after', function () {
            const elements = document.querySelectorAll('.slideshow-for-graphics .glide__slide--active video');
    
            intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
                element.play();
            });
        });
    }


    glide.mount();

    glide.on('run', function () {
        let activeVideo = document.querySelector('#image-slider .glide__slide--active video');
        if(activeVideo) {
            activeVideo.pause();
        }
    });

    glide.on('run.after', function () {
        let activeVideo = document.querySelector('#image-slider .glide__slide--active video');
        if(activeVideo) {
            activeVideo.play();
        }
    });
}

export function moveGlide(whichWay) {
    glide.go(whichWay);
}

window.initImageSlider = initImageSlider;