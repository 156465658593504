import Glide from "@glidejs/glide";
let glide = null;
let container = document.querySelector('.skew-container .glide__track');
let skrewSliderVideo = document.querySelectorAll('.skew-slider-video');

function initSkewSlider(id) {
    glide = new Glide('#' + id, {
        type: 'carousel',
        rewind: false, 
        bound: true,
        startAt: 0,
        gap: 24,
        perView: 1,
        animationDuration: 500,
        animationTimingFunc: 'ease-out',
        breakpoints: {
            900: {
                perView: 1,
                gap: 24,
            }
        }
    });
    
    glide.mount();

    glide.on('run.before', function(evt) {
        if(evt.direction == '<'){ 
            container.classList.add('is-moving-backward');
        } else {
            container.classList.add('is-moving-forward');
        }
    });

    glide.on('run.after', function(evt) {
        if(evt.direction == '<'){ 
            container.classList.remove('is-moving-backward');
        } else {
            container.classList.remove('is-moving-forward');
        }

        if(skrewSliderVideo) {
            skrewSliderVideo.forEach(element => {
                element.pause();
            });
        }
    });
    
}

export function moveGlide(whichWay) {
    glide.go(whichWay);
}


window.initSkewSlider = initSkewSlider;