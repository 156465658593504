let subscriptionBlock = document.querySelector('.subscription-plans');
let mobilePlansBlock = document.querySelector('.mobile-plans');
let plans = ['basic', 'standard', 'premium', 'enterprise']

if(subscriptionBlock) {

    let columns = {
        basic: subscriptionBlock.querySelectorAll('.column.basic'),
        standard: subscriptionBlock.querySelectorAll('.column.standard'),
        premium: subscriptionBlock.querySelectorAll('.column.premium'),
        enterprise: subscriptionBlock.querySelectorAll('.column.enterprise'),
    }
    
    let cells = {
		basic: subscriptionBlock.querySelectorAll('.cell.basic'),
		standard: subscriptionBlock.querySelectorAll('.cell.standard'),
		premium: subscriptionBlock.querySelectorAll('.cell.premium'),
		enterprise: subscriptionBlock.querySelectorAll('.cell.enterprise'),
	};

    plans.forEach(plan => {
        columns[plan].forEach(column => {
            column.addEventListener('mouseover', () => {
                columns[plan].forEach(column => {
                    column.classList.add('active');
                });

                cells[plan].forEach(cell => {
                    cell.classList.add('active');
                });
            });
            column.addEventListener('mouseout', () => {
                columns[plan].forEach(column => {
                    column.classList.remove('active');
                });

                cells[plan].forEach(cell => {
                    cell.classList.remove('active');
                });
            });
        });
    });
}

if(mobilePlansBlock) {
    window.addEventListener('load', () => {
        let mobilePlans = {
            basic: mobilePlansBlock.querySelectorAll('.plan.basic .feature-trigger'),
            standard: mobilePlansBlock.querySelectorAll('.plan.standard .feature-trigger'),
            premium: mobilePlansBlock.querySelectorAll('.plan.premium .feature-trigger'),
            enterprise: mobilePlansBlock.querySelectorAll('.plan.enterprise .feature-trigger'),
        }
    
        let planFeatures = {
            basic: mobilePlansBlock.querySelector('.plan-features.basic'),
            standard: mobilePlansBlock.querySelector('.plan-features.standard'),
            premium: mobilePlansBlock.querySelector('.plan-features.premium'),
            enterprise: mobilePlansBlock.querySelector('.plan-features.enterprise'),
        }
    
        let expandableFeatures = mobilePlansBlock.querySelectorAll('.feature.expandable');
        
        // Åpne standard tab ved load
        // mobilePlans['standard'][0].parentElement.classList.add('open');
        // planFeatures['standard'].classList.add('open');
        // openFeature(planFeatures['standard']);

        plans.forEach(plan => {
            mobilePlans[plan].forEach(mobilePlan => {
                mobilePlan.addEventListener('click', () => {
                    if(mobilePlan.parentElement.classList.contains('open')) {
                        mobilePlan.parentElement.classList.remove('open');
                        planFeatures[plan].classList.remove('open');
                        closeFeature(planFeatures[plan]);
                    } else {
                        closeAll(planFeatures, mobilePlans);
                        mobilePlan.parentElement.classList.add('open');
                        planFeatures[plan].classList.add('open');
                        openFeature(planFeatures[plan]);
                        
                        setTimeout(() => {
                            const y = mobilePlan.getBoundingClientRect().top + window.scrollY - 48;
                            window.scroll({
                                top: y,
                                behavior: 'smooth'
                            });
                        }, 700);
                    }
                });
            });       
        });

        expandableFeatures.forEach(feature => {
            feature.addEventListener('click', () => {
                if(feature.classList.contains('open')) {
                    feature.classList.remove('open');
                    closeFeature(feature.querySelector('.feature-description'));
                    decreaseFeature(feature.parentElement, feature);
                } else {
                    feature.classList.add('open');
                    openFeature(feature.querySelector('.feature-description'));
                    increaseFeature(feature.parentElement, feature);
                }
            });
        });
    });
}

function closeAll(planFeatures, mobilePlans) {
    plans.forEach(plan => {
        mobilePlans[plan][0].parentElement.classList.remove('open');
        planFeatures[plan].classList.remove('open');
        closeFeature(planFeatures[plan]);
    });
}

function openFeature(feature) {
    feature.style.maxHeight = feature.scrollHeight+'px';
}

function closeFeature(feature) {
    feature.style.maxHeight = 0;
}   

function increaseFeature(feature, expandableFeature) {
    feature.style.maxHeight = parseInt(feature.style.maxHeight) + expandableFeature.scrollHeight+'px';
}

function decreaseFeature(feature, expandableFeature) {
    feature.style.maxHeight = parseInt(feature.style.maxHeight) - parseInt(expandableFeature.style.maxHeight) +'px';
}

