let thingToLove = document.querySelector('.thing-to-love');

if(thingToLove) {
    let things = thingToLove.querySelectorAll('.thing');
    let videoContainers = thingToLove.querySelectorAll('.video-container');
    let exitVideo = thingToLove.querySelectorAll('.exit-video');
    let body = document.querySelector('body');

    let mobileTrigger = thingToLove.querySelectorAll('.video-container-mobile');

    videoContainers.forEach((element, index) => {
        let videoModule = element.querySelector('.video-module');
        element.addEventListener('click', () => {  
            if(element.classList.contains('active')) {
                element.classList.remove('visible');
                body.style.overflow = 'visible';
                setTimeout(() => {
                    element.classList.remove('active');
                }, 300);
            } else {
                element.classList.add('active');
                videoModule.style.transition = 'none';
                setTimeout(() => {
                    videoModule.style.transition = 'all 0.4s ease-in-out';
                    element.classList.add('visible');
                }, 100);
                body.style.overflow = 'hidden';
            }
        });
    });

    exitVideo.forEach((element, index) => {
        element.addEventListener('click', () => {
            videoContainers[index].classList.remove('visible');
            body.style.overflow = 'visible';
            setTimeout(() => {
                videoContainers[index].classList.remove('active');
            }, 300);
        });
    });


    if(window.innerWidth < 900) {
        mobileTrigger.forEach((element, index) => {
            let videoModule = videoContainers[index].querySelector('.video-module');
            element.addEventListener('click', () => {
                if(videoContainers[index].classList.contains('active')) {
                    videoContainers[index].classList.remove('visible');
                    body.style.overflow = 'visible';
                    setTimeout(() => {
                        videoContainers[index].classList.remove('active');
                    }, 300);
                } else {
                    videoContainers[index].classList.add('active');
                    videoModule.style.transition = 'none';
                    setTimeout(() => {
                        videoModule.style.transition = 'all 0.4s ease-in-out';
                        videoContainers[index].classList.add('visible');
                    }, 100);
                    body.style.overflow = 'hidden';
                }
            });
        });
    }

    if(things.length > 4){
        let retrictBox = thingToLove.querySelector('.restrict-visible-items');
        let maxHeight = things[0].offsetHeight + things[1].offsetHeight + things[2].offsetHeight + things[3].offsetHeight + (64*4);
        let thingContainer = thingToLove.querySelector('.things');
        let showMore = thingToLove.querySelector('.view-all-button');
        
        
        window.addEventListener('resize', () => {
            if(window.innerWidth < 900) {
                if(!showMore.parentElement.classList.contains('hidden')) {
                    maxHeight = things[0].offsetHeight + things[1].offsetHeight + things[2].offsetHeight + things[3].offsetHeight + (64*4); 
                    thingContainer.style.maxHeight = maxHeight + 'px';
                }
            } else {
                thingContainer.style.maxHeight = '4000px';
            }
        });

        window.addEventListener('load', () => {
            if(window.innerWidth < 900) {
                if(!showMore.parentElement.classList.contains('hidden')) {
                    maxHeight = things[0].offsetHeight + things[1].offsetHeight + things[2].offsetHeight + things[3].offsetHeight + (64*4); 
                    thingContainer.style.maxHeight = maxHeight + 'px';
                }
            } else {
                thingContainer.style.maxHeight = '4000px';
            }
        });

        showMore.addEventListener('click', () => {
            thingContainer.style.maxHeight = '4000px'
            showMore.parentElement.classList.add('hidden');
        });
    }
}