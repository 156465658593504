import { intersectionObserve as intersectionObserve } from "../src/_intersection-observer";

let numbers = document.querySelectorAll(".number");
const elements = document.querySelectorAll(".numbers-at-a-glance");
let interval = 1500;
let i = 1;


if(elements) {
	numbers.forEach((number) => {
		number.textContent = '';
	});

    intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
		(function (el) {
			if (intersectionRatio > 0 && !el.classList.contains("fade-in")) {
				numbers.forEach((number) => {
					setTimeout(function () {
					let startValue = 0;
					let endValue = parseInt(number.getAttribute("data-val"));
					let duration = Math.floor((interval / endValue));
					let counter = setInterval(function () {
							if(endValue < 400) {
								startValue += 1;
							} else if(endValue < 800) {
								startValue += 2;
							} else {	
								startValue += 4;
							}

							if(endValue-startValue < 400) {
								startValue += 1;
							} 

							number.textContent = startValue;
							if (startValue >= endValue) {
								number.textContent = endValue;
								clearInterval(counter);
							}
					}, duration);
					}, 300 * i);
					i++;
				});
			}
		})(element);
    });
}