import Glide from "@glidejs/glide";
let glide = null;
let container = document.querySelector('.testimonial-container .glide__track');

function initLogosCarouselSlider(id, speed, speedMobile) {
    let mobileSpeed = speedMobile*1000;
    let desktopSpeed = speed*1000;
    glide = new Glide('#' + id, {
        type: 'carousel',
        autoplay: 1,
        animationTimingFunc: 'linear',
        animationDuration: desktopSpeed,
        hoverpause: true,
        bound: true,
        startAt: 0,
        gap: 65,
        perView: 5,
        breakpoints: {
            900: {
                perView: 2.5,
                gap: 20,
                animationDuration: mobileSpeed,
            },
            1200: {
                perView: 4,
                gap: 40,
            }
        }
    });
    
    glide.mount();
}

window.initLogosCarouselSlider = initLogosCarouselSlider;