import Glide from "@glidejs/glide";
let glide = null;

function initLocationBoxGridSlider(id) {
    glide = new Glide('#' + id, {
        type: 'carousel',
        bound: true,
        startAt: 0,
        gap: 0,
        perView: 1,
    });
    
    glide.mount();
}

export function moveGlide(whichWay) {
    glide.go(whichWay);
}

window.initLocationBoxGridSlider = initLocationBoxGridSlider;