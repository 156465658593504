import Glide from "@glidejs/glide";
let glide = null;


function initCardSlider(id, numberOfCards) {
    window.addEventListener('load', function () {
        if(numberOfCards <= 2) {
            glide = new Glide('#' + id, {
                type: 'slider',
                startAt: 0,
                gap: 24,
                perView: 2,
                breakpoints: {
                    900: {
                        perView: 1,
                        gap: 16,
                    }
                }
            });
        } else {
            glide = new Glide('#' + id, {
                type: 'slider',
                startAt: 0,
                gap: 24,
                perView: 3,
                breakpoints: {
                    1050: {
                        perView: 2,
                        gap: 24,
                    }, 
                    900: {
                        perView: 1,
                        gap: 16,
                    }
                }
            });
        }
        glide.mount();    
    });
}


export function moveGlide(whichWay) {
    glide.go(whichWay);
}

window.initCardSlider = initCardSlider;