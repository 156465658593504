import { moveGlide } from './_card-slider-configuration.js';

let cardSlider = document.querySelector('.card-slider');
let clientX = 0;
let clientY = 0;

window.onload = function() {    
    if(cardSlider) {
        let arrowPrev = cardSlider.querySelector('.glide__arrows button.prev');
        let arrowNext = cardSlider.querySelector('.glide__arrows button.next');
        let cursorElements = cardSlider.querySelectorAll('.move-with-cursor');
        let nextField = cardSlider.querySelectorAll('.cursor-next-field');
        let prevField = cardSlider.querySelectorAll('.cursor-prev-field');
        let slides = document.querySelectorAll('.card-inner');
        let SliderContainer = cardSlider.querySelector('#card-slider');

        const delta = 6;
        let startX;
        let startY;

        window.addEventListener("mousemove", e => {
            clientX = e.clientX;
            clientY = e.clientY;

            cursorElements.forEach(element => {
                element.style.left = clientX+'px';
                element.style.top = clientY+'px';
            });
        });

        if(slides.length == 2) {
            SliderContainer.classList.add('two-slides');
        } else if(slides.length == 1) {
            SliderContainer.classList.add('one-slide');
        }

        nextField.forEach(element => {
            element.addEventListener("mouseover", e => {
                arrowNext.classList.add('active');
            });

            element.addEventListener("mouseleave", e => {
                arrowNext.classList.remove('active');
            });

            element.addEventListener("click", e => {
                moveGlide('>');
            });
        });

        prevField.forEach(element => {
            element.addEventListener("mouseover", e => {
                arrowPrev.classList.add('active');
            });

            element.addEventListener("mouseleave", e => {
                arrowPrev.classList.remove('active');
            });

            element.addEventListener("click", e => {
                moveGlide('<');
            });
        });

        slides.forEach((slide, index) => {
            slide.addEventListener('mousedown', function (event) {
                startX = event.pageX;
                startY = event.pageY;
            });
            
            slide.addEventListener('mouseup', function (event) {
                const diffX = Math.abs(event.pageX - startX);
                const diffY = Math.abs(event.pageY - startY);
            
                if (diffX < delta && diffY < delta) {
                    if(!slide.classList.contains('active')) {
                        unflipAllSlides(slides);
                        setTimeout(function() {
                            slide.classList.add('active');
                        }, 150);
                    } else {
                        slide.classList.remove('active');
                    }
                }
            });
        });
    }
};

function unflipAllSlides(slides) {
    slides.forEach((slide, index) => {
        slide.classList.remove('active');
    });
}

4