import { moveGlide } from './_location-box-grid-slider-configuration.js';

let locationSliders = document.querySelectorAll('.box.slider');
let clientX = 0;
let clientY = 0;

if(locationSliders.length > 0) {
    locationSliders.forEach((location, index) => {
        let arrowPrev = location.querySelector('.glide__arrows button.prev');
        let arrowNext = location.querySelector('.glide__arrows button.next');
        let nextField = location.querySelector('.cursor-next-field');
        let prevField = location.querySelector('.cursor-prev-field');
        
        if(window.innerWidth > 900) {
            window.addEventListener("mousemove", e => {
                clientX = e.clientX;
                clientY = e.clientY;
                arrowNext.style.left = clientX-24+'px';
                arrowNext.style.top = clientY-24+'px';
                arrowPrev.style.left = clientX-24+'px';
                arrowPrev.style.top = clientY-24+'px';
            });
        }
    
        nextField.addEventListener("mouseover", e => {
            arrowNext.classList.add('active');
        });
        
        nextField.addEventListener("mouseleave", e => {
            arrowNext.classList.remove('active');
        });
        
        nextField.addEventListener("click", e => {
            moveGlide('>');
        });
        
        prevField.addEventListener("mouseover", e => {
            arrowPrev.classList.add('active');
        });

        prevField.addEventListener("mouseleave", e => {
            arrowPrev.classList.remove('active');
        });

        prevField.addEventListener("click", e => {
            moveGlide('<');
        });

        arrowPrev.addEventListener("click", e => {
            moveGlide('<');
        });

        arrowNext.addEventListener("click", e => {
            moveGlide('>');
        });
    });
}