import { moveGlide } from './_slideshow-for-graphics-slider-configuration.js';
import { intersectionObserve } from './_intersection-observer.js';

const slideshowForGraphics = document.querySelector('.slideshow-for-graphics.slider');
const singleSlideForGraphics = document.querySelector('.slideshow-for-graphics.single');
let clientX = 0;
let clientY = 0;

if(slideshowForGraphics) {
    let arrowPrev = slideshowForGraphics.querySelector('.glide__arrows button.prev');
    let arrowNext = slideshowForGraphics.querySelector('.glide__arrows button.next');
    let nextField = slideshowForGraphics.querySelectorAll('.cursor-next-field');
    let prevField = slideshowForGraphics.querySelectorAll('.cursor-prev-field');

    window.addEventListener("mousemove", e => {
        clientX = e.clientX;
        clientY = e.clientY;
        arrowNext.style.left = clientX-24+'px';
        arrowNext.style.top = clientY-24+'px';
        arrowPrev.style.left = clientX-24+'px';
        arrowPrev.style.top = clientY-24+'px';
    });

    nextField.forEach(element => {
        element.addEventListener("mouseover", e => {
            arrowNext.classList.add('active');
        });

        element.addEventListener("mouseleave", e => {
            arrowNext.classList.remove('active');
        });

        element.addEventListener("click", e => {
            moveGlide('>');
        });
    });

    prevField.forEach(element => {
        element.addEventListener("mouseover", e => {
            arrowPrev.classList.add('active');
        });

        element.addEventListener("mouseleave", e => {
            arrowPrev.classList.remove('active');
        });

        element.addEventListener("click", e => {
            moveGlide('<');
        });
    });
} else if (singleSlideForGraphics) {
    window.addEventListener('load', function() {
        const elements = singleSlideForGraphics.querySelectorAll('video');
        
        intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
            element.play();
        });
    });
}