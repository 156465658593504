const $news = $('.vacancies-list article.tease');
const $moreButton = $('.vacancies-list ').find('.load-more');

let offset = 5; //Controls the offset on which articles to load. 3 highlight + 4 normal articles show on load.

if($news.length > 0) {
    let $totalPosts = $('#posts-total').data('total');

    $moreButton.click(function(e) {
        e.preventDefault();        
        let data = new FormData();
        data.append('action', 'get_news');
        data.append('offset', offset);
        $.ajax({
            type: 'POST',
            url: munu_scripts.ajax_url,
            cache: false,
            processData: false,
            contentType: false,
            data: data,
            success: function (res) {
                var appendedNews = res;
                
                $('.vacancies-list .list').append(appendedNews);
                let $totalPosts = $('.news-archive #posts-total').data('total');
                
                if (($('.news-archive .the-rest article.tease').length) < $totalPosts) {
                    $moreButton.removeClass('hide');
                } else {
                    $moreButton.addClass('hide');
                }
            }
        });
        offset = offset+4; //Increase the offset by the numbers of articles fetch per page.
    });  
}