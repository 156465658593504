let boxGrid = document.querySelectorAll('.box-grid');
if(boxGrid.length > 0) {
    boxGrid.forEach(function(grid) {
        let videos = grid.querySelectorAll('video');
        if(videos.length > 0) {
            videos.forEach(function(video) {
                video.parentElement.addEventListener('mouseover', function() {
                    video.play();
                });
                video.parentElement.addEventListener('mouseout', function() {
                    video.pause();
                });
            });
        }
    });
}